/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong"
  }, _provideComponents(), props.components), {SideBySide, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "how-to-protect-your-ears-while-traveling",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-protect-your-ears-while-traveling",
    "aria-label": "how to protect your ears while traveling permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to protect your ears while traveling"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Wherever you travel in the world, protecting your hearing is a must. From booming airplane engines to ear infections, traveling comes with a variety of auditory pitfalls. At the same time, traveling with hearing aids can be a hassle as well. This article will help vacationers avoid major risks and make the best use of their hearing aids during their travel adventures."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "common-hearing-risks-associated-with-traveling",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#common-hearing-risks-associated-with-traveling",
    "aria-label": "common hearing risks associated with traveling permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Common hearing risks associated with traveling"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/airplane-v2.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Travel is one of life’s greatest experiences, but not if it damages your hearing. There are plenty of risks that travelers need to be aware of. Firstly, there are problems associated with plane travel. Despite years of technological advances, planes are still exceptionally noisy places to be in. For periods of up to 10 hours, travelers can be subjected to noise levels in the region of 80 to 100 decibels.For reference, the National Institute for Occupational Health suggests a ", React.createElement(_components.strong, null, "maximum exposure of four hours to noises above 88 decibels"), ". Many air journeys will breach that, risking long-term hearing damage. Then there are more minor issues such as heightened tinnitus due to pressure changes, or the need to increase the volume of headphones to counteract cabin noise.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It can all build up into an unpleasant experience for anyone’s ears. And the risks don’t end when you get to Hawaii or Miami. A dip in the pool can lead to ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/otitis-externa/",
    className: "c-md-a"
  }, "swimmer’s ear (otitis externa) caused by bacteria in the water"), ", while train noise can also damage your hearing, particularly on subway networks where noise can be amplified. While there are definitely some auditory dangers associated with travel, there are also things we can do to mitigate them. Using earplugs in planes and trains, drying ears thoroughly after going for a swim, using noise canceling earphones to avoid turning the volume up too high – these measures can help us enjoy travel without damaging our ears."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-use-your-hearing-aid-during-plane-journeys",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-use-your-hearing-aid-during-plane-journeys",
    "aria-label": "how to use your hearing aid during plane journeys permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to use your hearing aid during plane journeys"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/airplane-inside.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "People who rely on hearing aids need to take extra care during plane journeys"), ". Let’s start with the good news: hearing aids are not covered by standard regulations regarding electronic devices like phones or laptops, so you can use them during take-off and landing without any problems. However, if your hearing aid has an FM receiver, it’s a good idea to turn that off during sensitive periods, as it can have the same effect on plane systems as mobile phone signals.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Aside from that, be sure to wear your hearing aid on board, and tune into the safety instructions. Use any noise reduction features included with your device to screen out background noise from the engines, and turn the volume up during the instructions. Almost all airlines include information sheets containing safety measures as well, so be sure to read through them to avoid missing anything important. After that, you can relax. Take your hearing aid out if you want to watch a movie, but be sure to stash it in your cabin baggage – not the seat pocket. That way, leaving it behind won’t be an option when your flight touches down."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "tips-for-traveling-with-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tips-for-traveling-with-hearing-aids",
    "aria-label": "tips for traveling with hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tips for traveling with hearing aids"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/lady-wearing-hearing-aids.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Traveling with hearing aids can be a pain, but it’s much easier if you plan ahead and familiarize yourself with a few handy tips."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Firstly, take advantage of cabin crew and ground staff. They will be trained to deal with people with hearing loss, and will be happy to talk directly at you, providing any support or advice you might need. Make sure that you have all of the charge you need as well. Take  ", React.createElement(_components.a, {
    href: "/hearing-aids/batteries/",
    className: "c-md-a"
  }, "a spare battery"), " or ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/rechargeable/",
    className: "c-md-a"
  }, "your charger"), " with you, and be sure to charge your device overnight. In hot climates, bringing a dehumidifier along could also help to remove moisture from your hearing aids. Having the right storage options is another important consideration. Take a special bag ", React.createElement(_components.a, {
    href: "/hearing-aids/accessories/",
    className: "c-md-a"
  }, "for your hearing aid and accessories"), ", preferably one that you can wear.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Then there are other accessories that tend to help travelers. For instance, you would be amazed how few people take vibrating alarms with them to wake them in the morning. Finally, when you are visiting attractions like museums or galleries, ask whether induction loop assistance is available. Most leading attractions provide guides for those with hearing problems and they can really enhance your travel experience, so don’t miss out."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "protect-your-hearing-and-enjoy-your-next-vacation-as-much-as-possible",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#protect-your-hearing-and-enjoy-your-next-vacation-as-much-as-possible",
    "aria-label": "protect your hearing and enjoy your next vacation as much as possible permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Protect your hearing and enjoy your next vacation as much as possible"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Traveling is supposed to be fun, but it can become a bit of a frustrating for those with hearing issues. There’s nothing enjoyable about losing your hearing aid, running out of charge in the middle of a long-haul flight, or developing swimmer’s ear on the first day of a beach vacation. However, there are things you can do to deal with every hearing-related holiday problem. By using your hearing aid properly, planning ahead and looking after your hearing when away from home, you can make travel easy on the ears, letting you concentrate on enjoying your trip to the full."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
